import { useFrame } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import {
    BoxGeometry,
    BufferGeometry,
    ConeGeometry,
    CylinderGeometry,
    IcosahedronGeometry,
    Mesh,
    MeshMatcapMaterial,
    NormalBufferAttributes,
    TetrahedronGeometry,
    TextureLoader,
    TorusKnotGeometry,
} from 'three';

export const boxGeometry = new BoxGeometry();
export const cylinderGeometry = new CylinderGeometry(0.7, 0.7, 0.3);
export const tetrahedronGeometry = new TetrahedronGeometry();
export const coneGeometry = new ConeGeometry();
export const torusKnotGeometry = new TorusKnotGeometry();
export const icosahedronGeometry = new IcosahedronGeometry();

const textureLoader = new TextureLoader();
export const matCap = textureLoader.load('/matcap/matcap4.png');

const material = new MeshMatcapMaterial({ matcap: matCap });

export const RotatingObject = ({ geometry }: { geometry?: BufferGeometry<NormalBufferAttributes> | undefined }) => {
    const objectRef = useRef<Mesh>(null);

    const rotationX = useMemo(() => {
        // between, -1.1 and -0.1, and, 0.1 and 1.1
        return (Math.random() > 0.5 ? 1 : -1) * (Math.random() + 0.1);
    }, []);
    const rotationY = useMemo(() => {
        // between, -1.1 and -0.1, and, 0.1 and 1.1
        return (Math.random() > 0.5 ? 1 : -1) * (Math.random() + 0.1);
    }, []);
    const randomGeometry = useMemo(() => {
        const geometryArray = [boxGeometry, tetrahedronGeometry, icosahedronGeometry];
        return geometryArray.at(Math.floor(Math.random() * geometryArray.length));
    }, []);

    useFrame((_, delta) => {
        if (objectRef.current) {
            objectRef.current.rotation.x += delta * rotationX;
            objectRef.current.rotation.y += delta * rotationY;
        }
    });

    return <mesh ref={objectRef} geometry={geometry ?? randomGeometry} material={material}></mesh>;
};
