import { Point, Points, useScroll } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export const Particles = () => {
    const { width, height } = useThree((state) => state.viewport);
    const scroll = useScroll();

    const size = 50 * scroll.pages;

    return (
        <Points limit={size}>
            <pointsMaterial size={0.4} depthWrite={false} />
            {Array.from({ length: size }).map((_, i) => (
                <Point
                    key={i}
                    position={[
                        (0.5 - Math.random()) * 2 * width,
                        height - Math.random() * height * (scroll.pages + 1),
                        (0 - Math.random()) * 25 - 10,
                    ]}
                />
            ))}
        </Points>
    );
};
