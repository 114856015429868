export const secretCode = `import styled from 'styled-components';

export const Secret = () => {
    return (
        <Container>
            <Title>"Secret" Project - Analyse "some" data</Title>
            <Section>
                <SectionTitle>Presentation</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        The "Secret" Project aims to make it easier for users to understand their "data" (for reasons of
                        secrecy, we have mocked up the design and will not go into detail).
                    </SectionParagraph>
                    <SectionParagraph>
                        To achieve this, we developed a mobile application for iOS and Android operating systems that
                        displays real-time data collected by Bluetooth (BLE) devices.
                    </SectionParagraph>
                    <SectionParagraph>
                        We worked closely with business managers and designers, which enabled us to iterate quickly and
                        remain pragmatic in the creation of this MVP.
                    </SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>Bluetooth</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        From the mobile application, we needed to connect to devices using the BLE (Bluetooth Low
                        Energy) protocol.
                    </SectionParagraph>
                    <SectionParagraph>
                        The different phases to be managed were: searching for nearby devices, displaying available
                        objects, pairing, collecting data and disconnecting.
                    </SectionParagraph>
                    <SectionParagraph>
                        We used Expo (React Native) to develop the application and the “react-native-ble-plx” library to
                        manage the Bluetooth connection.
                    </SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>React Native Skia</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        Once the data had been collected and transformed, we presented it in the form of a custom
                        graphic representing a car's speedometer.
                    </SectionParagraph>
                    <SectionParagraph>
                        The graphics were created entirely by hand using React Native Skia, a 2D graphics library.
                    </SectionParagraph>
                    <SectionParagraph>
                        What's more, the graphics adapt to the size of the screen to ensure optimal ergonomics even on
                        very small screens.
                    </SectionParagraph>
                </SectionContent>
            </Section>
        </Container>
    );
};

const Container = styled.div\`
    background-color: #1f1f1f;
    color: #cccccc;
    padding: 10px;
    gap: 20px;
\`;

const Title = styled.h1\`
    font-size: 20px;
\`;

const Section = styled.div\`
    gap: 15px;
\`;
const SectionTitle = styled.h2\`
    font-size: 16px;
\`;
const SectionContent = styled.div\`
    gap: 10px;
\`;

const SectionParagraph = styled.span\`
    font-size: 14px;
\`;
`;
