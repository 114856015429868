import { useFrame, useThree } from '@react-three/fiber';
import { Group, MathUtils, MeshStandardMaterial } from 'three';
import Bag from './Bag';
import SodaCan from './SodaCan';
import Burger from './Burger';
import TargetStand from './TargetStand';
import { icosahedronGeometry, RotatingObject } from '../basicComponents/RotatingObject';
import { useRef, useState } from 'react';
import { Text } from '@react-three/drei';
import { whiteBasicMaterial } from '../basicComponents/threeConstant';

const material = new MeshStandardMaterial({ color: '#393025' });

export default function Fridge(props: JSX.IntrinsicElements['group']) {
    const { height } = useThree((state) => state.viewport);
    const [isDoorOpened, setIsDoorOpened] = useState<boolean>(true);

    const doorRef = useRef<Group>(null);
    const redSodaRef = useRef<Group>(null);
    const yellowSodaRef = useRef<Group>(null);
    const bagRef = useRef<Group>(null);
    const burgerRef = useRef<Group>(null);

    const fridgeHeight = height * 0.6;
    const fridgeWidth = height * 0.2;

    useFrame((_, delta) => {
        if (
            !doorRef.current ||
            !redSodaRef.current ||
            !yellowSodaRef.current ||
            !bagRef.current ||
            !burgerRef.current
        ) {
            return;
        }

        doorRef.current.rotation.y = MathUtils.damp(doorRef.current.rotation.y, isDoorOpened ? 1.2 : 0, 4, delta);
        redSodaRef.current.scale.setScalar(MathUtils.damp(redSodaRef.current.scale.y, isDoorOpened ? 0 : 1, 8, delta));
        yellowSodaRef.current.scale.setScalar(
            MathUtils.damp(yellowSodaRef.current.scale.y, isDoorOpened ? 0 : 1, 8, delta)
        );
        bagRef.current.scale.setScalar(MathUtils.damp(bagRef.current.scale.y, isDoorOpened ? 0 : 1, 8, delta));
        burgerRef.current.scale.setScalar(MathUtils.damp(bagRef.current.scale.y, isDoorOpened ? 0 : 1, 8, delta));
    });

    return (
        <group {...props} dispose={null}>
            <group>
                {/* Left */}
                <mesh position={[-fridgeWidth / 2, 0, 0]} material={material}>
                    <boxGeometry args={[0.1, fridgeHeight, fridgeWidth]} />
                </mesh>
                {/* Right */}
                <mesh position={[fridgeWidth / 2, 0, 0]} material={material}>
                    <boxGeometry args={[0.1, fridgeHeight, fridgeWidth]} />
                </mesh>
                {/* Back */}
                <mesh rotation={[0, Math.PI / 2, 0]} position={[0, 0, -fridgeWidth / 2]} material={material}>
                    <boxGeometry args={[0.1, fridgeHeight, fridgeWidth + 0.1]} />
                </mesh>
                {/* Bottom */}
                <mesh rotation={[0, 0, Math.PI / 2]} position={[0, -fridgeHeight / 2 + 0.05, 0]} material={material}>
                    <boxGeometry args={[0.1, fridgeWidth, fridgeWidth]} />
                </mesh>
                {/* Top */}
                <mesh rotation={[0, 0, Math.PI / 2]} position={[0, fridgeHeight / 2 - 0.05, 0]} material={material}>
                    <boxGeometry args={[0.1, fridgeWidth, fridgeWidth]} />
                </mesh>
                {/* Door */}
                <group ref={doorRef} position={[fridgeWidth / 2, 0, fridgeWidth / 2 + 0.05]}>
                    <group position={[-fridgeWidth / 2, 0, 0]}>
                        {/* Left */}
                        <mesh position={[-fridgeWidth / 2, 0, 0]} material={material}>
                            <boxGeometry args={[0.1, fridgeHeight, 0.1]} />
                        </mesh>
                        {/* Right */}
                        <mesh position={[fridgeWidth / 2, 0, 0]} material={material}>
                            <boxGeometry args={[0.1, fridgeHeight, 0.1]} />
                        </mesh>
                        {/* Bottom */}
                        <mesh
                            rotation={[0, 0, Math.PI / 2]}
                            position={[0, -fridgeHeight / 2 + 0.05, 0]}
                            material={material}
                        >
                            <boxGeometry args={[0.1, fridgeWidth, 0.1]} />
                        </mesh>
                        {/* Top */}
                        <mesh
                            rotation={[0, 0, Math.PI / 2]}
                            position={[0, fridgeHeight / 2 - 0.05, 0]}
                            material={material}
                        >
                            <boxGeometry args={[0.1, fridgeWidth, 0.1]} />
                        </mesh>
                        {/* Front glass */}
                        <mesh rotation={[0, Math.PI / 2, 0]} position={[0, 0, 0]}>
                            <boxGeometry args={[0.1, fridgeHeight - 0.2, fridgeWidth + 0.1 - 0.2]} />
                            <meshPhysicalMaterial roughness={0} metalness={0} transmission={1} />
                        </mesh>
                    </group>
                </group>

                {/* Shelves */}
                <mesh rotation={[0, 0, Math.PI / 2]} position={[0, fridgeHeight * 0.325, 0]} material={material}>
                    <boxGeometry args={[0.1, fridgeWidth, fridgeWidth]} />
                </mesh>
                <mesh rotation={[0, 0, Math.PI / 2]} position={[0, fridgeHeight * 0.1625, 0]} material={material}>
                    <boxGeometry args={[0.1, fridgeWidth, fridgeWidth]} />
                </mesh>
                <mesh rotation={[0, 0, Math.PI / 2]} position={[0, fridgeHeight * 0, 0]} material={material}>
                    <boxGeometry args={[0.1, fridgeWidth, fridgeWidth]} />
                </mesh>
                <mesh rotation={[0, 0, Math.PI / 2]} position={[0, -fridgeHeight * 0.1625, 0]} material={material}>
                    <boxGeometry args={[0.1, fridgeWidth, fridgeWidth]} />
                </mesh>
                <mesh rotation={[0, 0, Math.PI / 2]} position={[0, -fridgeHeight * 0.325, 0]} material={material}>
                    <boxGeometry args={[0.1, fridgeWidth, fridgeWidth]} />
                </mesh>

                {/* Food */}
                <group ref={burgerRef} position={[fridgeWidth / 6, fridgeHeight * 0.1625 + 0.05, 0]}>
                    <Burger position={[0, 0, fridgeWidth / 3 - 0.2]} />
                    <Burger position={[0, 0, 0]} />
                </group>

                <group
                    position={[0, -fridgeHeight * 0.1625 + 0.05, 0]}
                    onClick={() => setIsDoorOpened(!isDoorOpened)}
                    onPointerEnter={() => {
                        document.body.style.cursor = 'pointer';
                    }}
                    onPointerLeave={() => {
                        document.body.style.cursor = 'default';
                    }}
                >
                    <TargetStand scale={0.25} position={[0, 0, fridgeWidth / 6]} />
                </group>
                <group position={[0, -fridgeHeight * 0.045, 0]}>
                    <Text
                        fontSize={0.2}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="center"
                        lineHeight={1}
                        material={whiteBasicMaterial}
                    >
                        Click me !
                    </Text>
                </group>

                <group scale={0.3} position={[-fridgeWidth / 8, fridgeHeight * 0.375, -fridgeWidth / 6]}>
                    <RotatingObject geometry={icosahedronGeometry} />
                </group>

                <group position={[-fridgeWidth / 8, -fridgeHeight * 0.325 + 0.05, 0]}>
                    <Bag position={[0, 0.15, 0]} rotation={[Math.PI / 2 + 0.1, -Math.PI / 2, 0]} />
                </group>

                <group position={[-fridgeWidth / 3, 0.05, 0]}>
                    <Bag scale={0.8} position={[0, 0, fridgeWidth / 3]} rotation={[0, -Math.PI / 2, 0]} />
                    <Bag scale={0.8} position={[0, 0, fridgeWidth / 6]} rotation={[0, -Math.PI / 2, 0]} />
                    <Bag scale={0.8} position={[0, 0, 0]} rotation={[0, -Math.PI / 2, 0]} />
                </group>

                <group ref={bagRef} position={[fridgeWidth / 3, -fridgeHeight * 0.325 + 0.05, 0]}>
                    <Bag scale={0.8} position={[0, 0, fridgeWidth / 3]} rotation={[0, -Math.PI / 2, 0]} />
                    <Bag scale={0.8} position={[0, 0, fridgeWidth / 6]} rotation={[0, -Math.PI / 2, 0]} />
                    <Bag scale={0.8} position={[0, 0, 0]} rotation={[0, -Math.PI / 2, 0]} />
                </group>

                <group position={[0, 0.05, 0]}>
                    <group ref={redSodaRef}>
                        <SodaCan scale={1.2} position={[-0.15, 0, fridgeWidth / 3]} />
                        <SodaCan scale={1.2} position={[-0.15, 0, fridgeWidth / 6]} />
                        <SodaCan scale={1.2} position={[-0.15, 0, 0]} />
                    </group>

                    <group>
                        <SodaCan scale={1.2} position={[0.15, 0, fridgeWidth / 3]} />
                        <SodaCan scale={1.2} position={[0.15, 0, fridgeWidth / 6]} />
                        <SodaCan scale={1.2} position={[0.15, 0, 0]} />
                    </group>
                </group>

                <group position={[fridgeWidth / 3, fridgeHeight * 0.325 + 0.05, 0]}>
                    <SodaCan color={'#62e67d'} scale={1.2} position={[0, 0, fridgeWidth / 3]} />
                    <SodaCan color={'#62e67d'} scale={1.2} position={[0, 0, fridgeWidth / 6]} />
                    <SodaCan color={'#62e67d'} scale={1.2} position={[0, 0, 0]} />
                    <SodaCan color={'#62e67d'} scale={1.2} position={[0, 0, -fridgeWidth / 6]} />
                </group>
                <group position={[-fridgeWidth / 4, fridgeHeight * 0.1625 + 0.05, 0]}>
                    <SodaCan color={'#b070ef'} scale={1.2} position={[0, 0, fridgeWidth / 3]} />
                    <SodaCan color={'#b070ef'} scale={1.2} position={[0, 0, fridgeWidth / 6]} />
                    <SodaCan color={'#b070ef'} scale={1.2} position={[0, 0, 0]} />
                    <SodaCan color={'#b070ef'} scale={1.2} position={[0, 0, -fridgeWidth / 6]} />
                </group>
                <group position={[-fridgeWidth / 3, -fridgeHeight * 0.5 + 0.1, 0]}>
                    <SodaCan color={'#70cdef'} scale={1.2} position={[0, 0, fridgeWidth / 3]} />
                    <SodaCan color={'#70cdef'} scale={1.2} position={[0, 0, fridgeWidth / 6]} />
                    <SodaCan color={'#70cdef'} scale={1.2} position={[0, 0, 0]} />
                </group>
                <group ref={yellowSodaRef} position={[-fridgeWidth / 6, -fridgeHeight * 0.5 + 0.1, 0]}>
                    <SodaCan color={'#e4e662'} scale={1.2} position={[0, 0, fridgeWidth / 3]} />
                    <SodaCan color={'#e4e662'} scale={1.2} position={[0, 0, fridgeWidth / 6]} />
                    <SodaCan color={'#e4e662'} scale={1.2} position={[0, 0, 0]} />
                    <SodaCan color={'#e4e662'} scale={1.2} position={[0, 0, -fridgeWidth / 6]} />
                </group>
                <group position={[0, -fridgeHeight * 0.5 + 0.1, 0]}>
                    <SodaCan color={'#8eef70'} scale={1.2} position={[0, 0, fridgeWidth / 3]} />
                </group>
            </group>
        </group>
    );
}
