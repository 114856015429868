import { FileType } from './directories';
import styled from 'styled-components';

export const ImageContent = ({
    selectedFileIndex,
    openedFiles,
}: {
    selectedFileIndex: number;
    openedFiles: FileType[];
}) => {
    const selectedFile = openedFiles[selectedFileIndex];

    return (
        <ImageContainer>
            <Image src={selectedFile.content} />
        </ImageContainer>
    );
};

const ImageContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding: 25px;
    min-height: 0%;
    min-width: 0%;
`;

const Image = styled.img`
    object-fit: contain;

    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
`;
