import styled from 'styled-components';
import { DirectoryType, FileType, getSvgForExtension, rootDirectory } from './directories';
import { useEffect, useRef, useState } from 'react';

function getFilesFromDirectory(directory: DirectoryType, uriPrefix: string): (FileType & { uri: string })[] {
    return directory.directories.flatMap((value) =>
        'directories' in value
            ? getFilesFromDirectory(value, `${uriPrefix}${directory.title}/`)
            : { ...value, uri: `${uriPrefix}${value.title}` }
    );
}

const files = getFilesFromDirectory(rootDirectory, '');

export const TopBar = ({ addOpenedFile }: { addOpenedFile: (file: FileType) => void }) => {
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('one');

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setIsModalOpened(false);
                setSearchText('');
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setIsModalOpened]);

    const foundFiles = files.filter(({ title }) => title.toLowerCase().includes(searchText.toLowerCase()));
    return (
        <Container>
            <FakeSearchBar ref={ref} onClick={() => setIsModalOpened(true)}>
                <FakeSearchTitle>🔎</FakeSearchTitle>
                <FakeSearchTitle>portfolio</FakeSearchTitle>
                {isModalOpened ? (
                    <SearchModal>
                        <SearchInput
                            value={searchText}
                            onChange={(event) => setSearchText(event.target.value)}
                            placeholder="Search files by name"
                            autoFocus
                        />
                        <ResultsContainer>
                            {foundFiles.map((file, index) => (
                                <Result
                                    key={index}
                                    onClick={(event) => {
                                        addOpenedFile(file);
                                        setIsModalOpened(false);
                                        setSearchText('');
                                        event.stopPropagation();
                                    }}
                                >
                                    <TabIcon src={getSvgForExtension(file.extension)} />
                                    <FileTitle>{file.title}</FileTitle>
                                    <UriText>{file.uri}</UriText>
                                </Result>
                            ))}
                        </ResultsContainer>
                    </SearchModal>
                ) : null}
            </FakeSearchBar>
        </Container>
    );
};

const Container = styled.div`
    background-color: #1f1f1f;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    flex-shrink: 0;

    border-bottom: 0.1px solid #43423d;

    padding: 7px;
`;

const SearchInput = styled.input`
    display: block;
    outline: none;
    background-color: #373737;
    border: 1px solid #14a9ff;
    color: #cccccc;
    border-radius: 3px;
    padding: 4px 5px;
    font-family: 'RobotoRegular';
    font-size: 12px;
`;

const FakeSearchBar = styled.div`
    cursor: pointer;
    position: relative;
    background-color: #2a2a2a;
    width: 40%;
    border: 0.1px solid #43423d;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const FakeSearchTitle = styled.div`
    color: #cccccc;
    font-family: 'RobotoRegular';
    font-size: 12px;
`;

const SearchModal = styled.div`
    position: absolute;
    top: -1px;
    background-color: #2a2a2a;
    width: 101%;
    padding: 10px 5px;
    z-index: 10;

    display: flex;
    flex-direction: column;

    border-radius: 5px;
    border: 0.1px solid #43423d;

    box-shadow: #000000 0px 0px 10px 0px;
`;

const ResultsContainer = styled.div`
    margin-top: 5px;
`;

const Result = styled.div`
    font-family: 'RobotoRegular';
    padding: 2px 5px;

    display: flex;
    align-items: center;

    border-radius: 5px;

    gap: 5px;

    &:hover {
        background-color: #555555;
    }
`;

const TabIcon = styled.img`
    width: 20px;
    height: 20px;
`;

const FileTitle = styled.div`
    color: #cccccc;
    font-size: 14px;
`;

const UriText = styled.div`
    font-size: 11px;
    color: #818181;
`;
