export const totemCode = `import styled from 'styled-components';

export const Totem = () => {
    return (
        <Container>
            <Title>Totem - More than a connected fridge</Title>
            <Section>
                <SectionTitle>Presentation</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        Totem has two products. The first is a product delivery service. The second is an office store
                        with connected fridges.
                    </SectionParagraph>
                    <SectionParagraph>
                        For the first service, a dedicated website allows customers to select the products to be
                        delivered each week (fruit, snacks, drinks, etc.).
                    </SectionParagraph>
                    <SectionParagraph>
                        For the office store, Totem autonomously manages the products made available to your employees.
                        They can pick up their lunch from our connected fridges, or buy extra food when they run out of
                        toothpaste or washing up liquid.
                    </SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>Team Management</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        During our adventure at Totem, we each set up a different team: one to work on the mobile
                        application for our users, the other on the company's internal tools.
                    </SectionParagraph>
                    <SectionParagraph>
                        Each two-person team worked closely with the business. The first team worked with a designer, a
                        PO and the marketing department. The second team worked with the Operations Manager, the
                        Preparers and the CSM.
                    </SectionParagraph>
                    <SectionParagraph>
                        When we arrived at Totem, we set up an agile process to work with the business: organising tasks
                        in Asana, productivity meetings and week-long sprints.
                    </SectionParagraph>
                    <SectionParagraph>
                        We also increased collaboration between developers by emphasising PR reviews and peer
                        programming.
                    </SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>Loyalty Program</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        The team responsible for the mobile application has added elements that are attractive to users.
                    </SectionParagraph>
                    <SectionParagraph>
                        They started by adding daily offers, highlighting products that were nearing their expiry date.
                    </SectionParagraph>
                    <SectionParagraph>
                        Then they added promotions based on time of day and user preferences.
                    </SectionParagraph>
                    <SectionParagraph>
                        Finally, the Totanimals project was born. This is a gamified loyalty card. Every purchase earns
                        gems that can be exchanged for cash.
                    </SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>Scalability</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        The team responsible for internal tools has increased sourcing from suppliers and shipping to
                        customers.
                    </SectionParagraph>
                    <SectionParagraph>
                        An administration area was created to manage stock and customer stock. Then to manage orders.
                        Thanks to the data team, sales forecasts were used to automate the creation and dispatch of
                        orders.
                    </SectionParagraph>
                    <SectionParagraph>
                        At the same time, an application was created to help prepare customer orders and store fridges
                        on site.
                    </SectionParagraph>
                    <SectionParagraph>
                        Only one person was needed to manage the different orders (in addition to the pickers and
                        delivery drivers).
                    </SectionParagraph>
                </SectionContent>
            </Section>
        </Container>
    );
};

const Container = styled.div\`
    background-color: #1f1f1f;
    color: #cccccc;
    padding: 10px;
    gap: 20px;
\`;

const Title = styled.h1\`
    font-size: 20px;
\`;

const Section = styled.div\`
    gap: 15px;
\`;
const SectionTitle = styled.h2\`
    font-size: 16px;
\`;
const SectionContent = styled.div\`
    gap: 10px;
\`;

const SectionParagraph = styled.span\`
    font-size: 14px;
\`;
`;
