import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Routing from 'Routing';
import 'style.css';

function App() {
    return (
        <Container className="App">
            <link rel="preload" href="fonts/Plumpfull.ttf" as="font" type="font/ttf" crossOrigin="anonymous" />
            <link rel="preload" href="fonts/Roboto-Regular.ttf" as="font" type="font/ttf" crossOrigin="anonymous" />
            <link rel="preload" href="fonts/Roboto-Bold.ttf" as="font" type="font/ttf" crossOrigin="anonymous" />
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<Routing />} />
                </Routes>
            </BrowserRouter>
        </Container>
    );
}

export default App;

const Container = styled.div`
    @font-face {
        font-family: 'Plumpfull';
        src:
            local('Plumpfull'),
            url('fonts/Plumpfull.ttf') format('truetype');
    }
    @font-face {
        font-family: 'RobotoRegular';
        src:
            local('RobotoRegular'),
            url('fonts/Roboto-Regular.ttf') format('truetype');
    }
    @font-face {
        font-family: 'RobotoBold';
        src:
            local('RobotoBold'),
            url('fonts/Roboto-Bold.ttf') format('truetype');
    }
`;
