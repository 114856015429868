import { Canvas } from '@react-three/fiber';
import styled from 'styled-components';

import { Environment, Loader, Scroll, ScrollControls } from '@react-three/drei';
import { Space3DFixed } from './Space3DFixed';
import { Space3DScroll } from './Space3DScroll';
import { SpaceHTMLScroll } from './SpaceHTMLScroll';
import { SpaceHTMLFixed } from './SpaceHTMLFixed';
import { Suspense } from 'react';

export const Space = () => {
    return (
        <Container>
            <Canvas camera={{ position: [0, -3.2, 40], fov: 12 }} gl={{ stencil: true }}>
                <color args={['#090611']} attach="background" />
                <Environment preset="city" />
                <hemisphereLight position={[0, 0, 1]} intensity={2} />

                <Suspense>
                    <ScrollControls pages={15} damping={0.1} maxSpeed={0.5}>
                        <Space3DFixed />

                        <Scroll>
                            <Space3DScroll />
                        </Scroll>
                        <Scroll html>
                            <SpaceHTMLScroll />
                        </Scroll>
                        <SpaceHTMLFixed />
                    </ScrollControls>
                </Suspense>
            </Canvas>
            <Loader
                dataInterpolation={() => 'Loading Experience'}
                containerStyles={{ backgroundColor: '#090611', fontFamily: 'Arial', fontSize: 30 }}
                innerStyles={{
                    width: '50vw',
                    height: 5,
                    borderRadius: 5,
                }}
                barStyles={{ borderRadius: 5 }}
            />
            {/* <LoadingScreen /> */}
        </Container>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black;
`;
