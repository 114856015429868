import { useEffect, useState } from 'react';
import styled from 'styled-components';

const animationDuration = 1500;

export const BouncingLetter = ({ letter, index }: { letter: string; index: number }) => {
    const [isAnimated, setIsAnimated] = useState(false);

    // useEffect(() => {
    //     function randomAnimationDelay() {
    //         handle = setTimeout(
    //             () => {
    //                 setIsAnimated(true);
    //                 setTimeout(() => setIsAnimated(false), animationDuration);
    //                 randomAnimationDelay();
    //             },
    //             2 * animationDuration + Math.random() * 3000
    //         );
    //     }

    //     let handle = setTimeout(
    //         () => {
    //             setIsAnimated(true);
    //             setTimeout(() => setIsAnimated(false), animationDuration);
    //             randomAnimationDelay();
    //         },
    //         300 + Math.random() * 3000
    //     );

    //     return () => {
    //         clearTimeout(handle);
    //     };
    // }, []);

    return (
        <Letter $isAnimated={isAnimated} $index={index}>
            {letter}
        </Letter>
    );
};

const Letter = styled.div<{ $isAnimated: boolean; $index: number }>`
    color: white;
    font-family: Plumpfull;
    font-size: 70px;
    font-weight: bold;
    letter-spacing: -2px;

    z-index: ${({ $index }) => $index};

    text-shadow:
        -20px 10px 10px rgba(0, 0, 0, 0.3),
        0 20px 50px rgba(0, 0, 0, 0.3);
    transform: matrix(1, 0, 0, 1, 0, 0);
    transform-origin: 50% 100%;
    will-change: text-shadow, transform;

    animation: ${({ $isAnimated }) =>
        $isAnimated
            ? `bounce ${animationDuration}ms 1 alternate forwards cubic-bezier(0.175, 0.885, 0.32, 1.275)`
            : 'none'};

    @keyframes bounce {
        0%,
        to {
            transform: matrix(1, 0, 0, 1, 0, 0);
        }

        25%,
        75% {
            text-shadow:
                -5px 2px 2px rgba(0, 0, 0, 0.3),
                0 5px 12px rgba(0, 0, 0, 0.3);
            transform: matrix(1.1, 0, 0, 0.8, 0, 0);
        }

        50% {
            transform: matrix(0.8, 0, 0, 1.2, 0, 0) translateY(-40px);
            text-shadow:
                -40px 20px 20px rgba(0, 0, 0, 0.3),
                0 40px 100px rgba(0, 0, 0, 0.3);
        }
    }
`;
