import { DragControls, Line, Mask, Text, useMask } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Vancouver } from '../models/Vancouver';
import { ImageGallery } from './ImageGallery';
import { Highlights } from './Highlights';
import { whiteBasicMaterial } from '../basicComponents/threeConstant';

const highlights = [
    {
        description:
            "This is a mobile treasure hunt/guided tour application for France's major cities. Come and discover the historical heritage of your city by solving puzzles.\n" +
            '\n' +
            'To create the treasure hunts, we carried out historical research on the various sites in the neighbourhoods. We then designed the appropriate puzzles.\n' +
            '\n' +
            'https://enigmo-games.com/\n' +
            '\n' +
            'To find out more, hover over the badges on the right.\n',
        imageUrl: '/images/badges/project.png',
        title: 'Project',
    },
    {
        description:
            'We developed the mobile application using Expo (React Native). This framework allows us to develop iOS and Android versions in parallel.\n' +
            '\n' +
            "The diversity of the puzzles led us to use phone-specific mechanics. For example, some puzzles use the accelerometer, some use the user's position, some use the camera, and we even have an augmented reality puzzle.\n" +
            '\n' +
            "We've also set up an online chat to deal with urgent customer issues (so they don't get stuck in the middle of a treasure hunt).\n",
        imageUrl: '/images/badges/expo.png',
        title: 'Expo (React Native)',
    },
    {
        description:
            'Tourists are one of our target audiences. So we needed to translate the application into a variety of languages, including English.\n' +
            '\n' +
            'We set up a translation system accessible from the mobile application, the shop site and the admin site.\n' +
            '\n' +
            'Translations are managed on the admin site: when creating a treasure hunt, each text field has to be filled in in the different languages.\n',
        imageUrl: '/images/badges/translate.png',
        title: 'Translation',
    },
    {
        description:
            'To improve the SEO of our shop, we decided to use NextJS, a server-side rendering framework.\n' +
            '\n' +
            'With the same goal in mind, we set up a sitemap generation system that takes into account "canonical" or "alternate" urls (our urls are translated into the appropriate language for better referencing).\n' +
            '\n' +
            'We also followed best practices such as adding title tags, meta descriptions and robots.txt files.\n' +
            '\n' +
            'Finally, we added structured data (JSON-LD) to our product pages so that they appear in a stylised way in searches.\n',
        imageUrl: '/images/badges/next.png',
        title: 'SEO',
    },
    {
        description:
            'To get the word out, we tried a number of marketing techniques.\n' +
            '\n' +
            'First, we partnered with Escape Days, an event that brings together many escape game brands.\n' +
            '\n' +
            'Then we created an Instagram page where we posted mini-games once a week.\n' +
            '\n' +
            "Finally, we used Google's AdSense to appear in sponsored results on Google searches.\n",
        imageUrl: '/images/badges/google-ads.png',
        title: 'Google Ads',
    },
];

export const ProjectEnigmo = () => {
    const { width, height } = useThree((state) => state.viewport);

    const stencil = useMask(1, true);

    return (
        <group>
            <group position-x={width / 2 - width / 30}>
                <group>
                    <Text
                        anchorX="right"
                        fontSize={0.5}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="right"
                        lineHeight={1}
                        maxWidth={width - width / 10}
                        material={whiteBasicMaterial}
                    >
                        Enigmo - Treasure hunts in your city
                    </Text>
                </group>

                <Highlights isRight highlights={highlights} />

                <ImageGallery
                    imageUrls={[
                        '/images/enigmo/1.jpeg',
                        '/images/enigmo/2.jpeg',
                        '/images/enigmo/3.jpeg',
                        '/images/enigmo/4.jpeg',
                        '/images/enigmo/5.jpeg',
                        '/images/enigmo/6.jpeg',
                    ]}
                    isRight={true}
                />
            </group>

            <group position={[-width / 5, -height / 3, 0]}>
                <group position={[-width / 10, 0, 0]}>
                    <DragControls>
                        <group
                            position={[0, 0, 0]}
                            onPointerEnter={() => {
                                document.body.style.cursor = 'pointer';
                            }}
                            onPointerLeave={() => {
                                document.body.style.cursor = 'default';
                            }}
                        >
                            <mesh position={[0, 0, 0.1]}>
                                <ringGeometry args={[1 - 0.1, 1 + 0.01, 64]} />
                                <meshBasicMaterial color="#ffffff" toneMapped={false} />
                            </mesh>
                            <Line
                                points={[
                                    [0, -0.1, 0.09],
                                    [1.1, -1.5, 0.09],
                                ]} // Array of points, Array<Vector3 | Vector2 | [number, number, number] | [number, number] | number>
                                lineWidth={20} // In pixels (default)
                                segments // If true, renders a THREE.LineSegments2. Otherwise, renders a THREE.Line2
                                toneMapped={false}
                                color={'#ffd7b7'}
                                {...stencil}
                            />
                            <Mask id={1} position={[0, 0, 0]}>
                                <circleGeometry args={[1, 64]} />
                            </Mask>
                        </group>
                    </DragControls>
                    <group>
                        <Text
                            position={[0, 0 - 1.2, 0]}
                            fontSize={0.2}
                            fontWeight={700}
                            letterSpacing={-0.02}
                            textAlign="center"
                            lineHeight={1}
                        >
                            Drag me !
                            <meshBasicMaterial color={'#ffffff'} toneMapped={false} {...stencil} />
                        </Text>
                    </group>
                </group>
                <group scale={3} rotation={[0.6, -2.4, 0]} position={[0, 0, -5]}>
                    <Vancouver />
                </group>
            </group>
        </group>
    );
};
