export const oneflashCode = `import styled from 'styled-components';

export const OneFlash = () => {
    return (
        <Container>
            <Title>Oneflash - Never run out of battery</Title>
            <Section>
                <SectionTitle>Presentation</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        OneFlash provides portable battery stations in high-density locations: airports, shopping malls,
                        stores, gyms, hospitals and festivals.
                    </SectionParagraph>
                    <SectionParagraph>
                        We were tasked with developing middleware to communicate with the stations to unlock the
                        portable batteries.
                    </SectionParagraph>
                    <SectionParagraph>
                        Our objectives? To improve performance and scalability over the old middleware and to define a
                        clear deployment process.
                    </SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>MQTT</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        The stations communicate using the MQTT protocol. So we needed to set up an MQTT broker to route
                        messages from the stations to the backend.
                    </SectionParagraph>
                    <SectionParagraph>
                        To do this, we carried out a comparative study of different brokers and chose two: EMQX and
                        VerneMQ. We chose EMQX because of its ease of installation.
                    </SectionParagraph>
                    <SectionParagraph>
                        EMQX allows for clustering, which allowed us to create three clusters working together to
                        improve resilience.
                    </SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>Kubernetes</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        We decided to deploy our solution on Kubernetes. This easy-to-use technology allows the customer
                        to take control of the project once the mission is complete.
                    </SectionParagraph>
                    <SectionParagraph>
                        Our Kubernetes cluster consists of several nodes, including one for the EMQX cluster (which
                        requires more resources) and one for the API pods, which require autoscaling capabilities.
                    </SectionParagraph>
                    <SectionParagraph>
                        We've set up the resources needed to keep the cluster running smoothly: an Ingress to access the
                        different parts of the middleware using SSL, an HPA to manage API scaling, and so on.
                    </SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>Google Cloud</SectionTitle>
                <SectionContent>
                    <SectionParagraph>We used Google Cloud Platform to deploy our Kubernetes cluster.</SectionParagraph>
                    <SectionParagraph>
                        When deploying our database, we added a whitelist of IP addresses to increase security. To do
                        this, we had to manage the access points of our cluster by turning it into a private cluster. We
                        then created routing tools, such as a cloud NAT, to authorise internet access to our API.
                    </SectionParagraph>
                    <SectionParagraph>
                        We wrote clear documentation that allowed us to reinstall the middleware from scratch (creating
                        the Kubernetes cluster, Mongo database, VPC, etc.).
                    </SectionParagraph>
                </SectionContent>
            </Section>
        </Container>
    );
};

const Container = styled.div\`
    background-color: #1f1f1f;
    color: #cccccc;
    padding: 10px;
    gap: 20px;
\`;

const Title = styled.h1\`
    font-size: 20px;
\`;

const Section = styled.div\`
    gap: 15px;
\`;
const SectionTitle = styled.h2\`
    font-size: 16px;
\`;
const SectionContent = styled.div\`
    gap: 10px;
\`;

const SectionParagraph = styled.span\`
    font-size: 14px;
\`;
`;
