import { useFrame, useThree } from '@react-three/fiber';
import { Macbook1 } from './3D/models/Macbook';
import { Float, useScroll } from '@react-three/drei';
import { useRef } from 'react';
import { Group, MathUtils } from 'three';
import { Iphone } from './3D/models/Iphone';
import { AppearingSlogan } from './3D/AppearingSlogan';
import { icosahedronGeometry, RotatingObject } from './3D/basicComponents/RotatingObject';

export const Space3DFixed = () => {
    const scroll = useScroll();
    const { width, height } = useThree((state) => state.viewport);

    const deviceRef = useRef<Group>(null);
    const macbookRef = useRef<Group>(null);
    const macbookScreenRef = useRef<Group>(null);
    const iphoneRef = useRef<Group>(null);
    const sloganRef = useRef<Group>(null);
    const bigObjectRef = useRef<Group>(null);

    useFrame((_, delta) => {
        if (
            !macbookRef.current ||
            !macbookScreenRef.current ||
            !iphoneRef.current ||
            !sloganRef.current ||
            !bigObjectRef.current
        ) {
            return;
        }

        // camera.position.x = MathUtils.lerp(camera.position.x, pointer.x * 0.8, 0.03);
        // camera.position.y = MathUtils.lerp(camera.position.y, -3.2 + pointer.y * 0.8, 0.01);

        const r1 = scroll.range(0 / scroll.pages, 1 / scroll.pages);
        const r2 = scroll.range(1 / scroll.pages, 1 / scroll.pages);
        const r4 = scroll.range(3 / scroll.pages, 1.5 / scroll.pages);
        const r6 = scroll.range(6.1 / scroll.pages, 1 / scroll.pages);

        macbookScreenRef.current.rotation.x = MathUtils.damp(
            macbookScreenRef.current.rotation.x,
            Math.PI - (Math.PI / 2) * r1 - r2 * 0.33,
            4,
            delta
        );

        macbookRef.current.rotation.y = MathUtils.damp(macbookRef.current.rotation.y, Math.PI * 0.15 * r2, 4, delta);
        macbookRef.current.position.x = MathUtils.damp(macbookRef.current.position.x, (-width / 7) * r2, 4, delta);
        macbookRef.current.position.y = MathUtils.damp(
            macbookRef.current.position.y,
            -height / 2.65 + (height / 20) * r2 + 1.5 * height * r4,
            4,
            delta
        );
        macbookRef.current.scale.setScalar(MathUtils.damp(macbookRef.current.scale.z, 1 + 0.24 * (1 - r1), 4, delta));

        iphoneRef.current.rotation.x = MathUtils.damp(
            iphoneRef.current.rotation.x,
            Math.PI / 2 - (Math.PI / 2) * r2 * 1.2,
            4,
            delta
        );
        iphoneRef.current.rotation.z = MathUtils.damp(
            iphoneRef.current.rotation.z,
            -(Math.PI / 2) * r2 * 0.1,
            4,
            delta
        );
        iphoneRef.current.rotation.y = MathUtils.damp(iphoneRef.current.rotation.y, -Math.PI * 0.2 * r2, 4, delta);
        iphoneRef.current.position.x = MathUtils.damp(iphoneRef.current.position.x, (width / 4) * r2, 4, delta);
        iphoneRef.current.position.y = MathUtils.damp(
            iphoneRef.current.position.y,
            -height / 2.65 + (height / 20) * r1 + 1.5 * height * r4,
            4,
            delta
        );
        iphoneRef.current.scale.setScalar(MathUtils.damp(iphoneRef.current.scale.z, 1 + 0.5 * r2, 4, delta));

        sloganRef.current.position.y = MathUtils.damp(
            sloganRef.current.position.y,
            (height * 2) / 5 + 0.5 * height * r4,
            4,
            delta
        );

        bigObjectRef.current.position.y = MathUtils.damp(
            bigObjectRef.current.position.y,
            1.3 * height - (1 - r6) * height * 4,
            4,
            delta
        );
    });

    return (
        <group>
            <Float
                speed={5} // Animation speed, defaults to 1
                rotationIntensity={0} // XYZ rotation intensity, defaults to 1
                floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                floatingRange={[-0.1, 0.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
            >
                <group ref={deviceRef}>
                    <group ref={macbookRef} position={[0, -height / 2.65, 0]}>
                        <Macbook1 ref={macbookScreenRef} scale={width * 0.01} visible={true} />
                    </group>
                    <group ref={iphoneRef} position={[0, -height / 2.65, -6]} rotation={[Math.PI / 2, 0, 0]}>
                        <Iphone />
                    </group>
                </group>
            </Float>
            <group ref={sloganRef} position={[0, 0, -10]}>
                <AppearingSlogan />
            </group>

            <group ref={bigObjectRef} position={[0, -6.5 * height, 10]} scale={3.2}>
                <RotatingObject geometry={icosahedronGeometry} />
            </group>
        </group>
    );
};
