import styled from 'styled-components';
import { FileType, getSvgForExtension } from './directories';

export const TabBar = ({
    closeOpenedFile,
    selectedFileIndex,
    setSelectedFileIndex,
    openedFiles,
}: {
    closeOpenedFile: (fileIndex: number) => void;
    selectedFileIndex: number;
    setSelectedFileIndex: React.Dispatch<React.SetStateAction<number>>;
    openedFiles: FileType[];
}) => {
    return (
        <Container>
            {openedFiles.map((openedFile, index) => {
                const isSelected = selectedFileIndex === index;

                return (
                    <Tab key={index} $isSelected={isSelected} onClick={() => setSelectedFileIndex(index)}>
                        <TabIcon src={getSvgForExtension(openedFile.extension)} />
                        <TabTitle>{openedFile.title}</TabTitle>
                        <CloseIconContainer
                            onClick={(event) => {
                                event.stopPropagation();
                                closeOpenedFile(index);
                            }}
                        >
                            <CloseIcon src={'/svgs/x.svg'} />
                        </CloseIconContainer>
                    </Tab>
                );
            })}
            <Filler />
        </Container>
    );
};

const Container = styled.div`
    background-color: #181818;
    height: 39px;
    display: flex;
    flex-direction: row;
    overflow: auto;
    flex-shrink: 0;
    scrollbar-color: #747474 transparent;
    scrollbar-width: thin;
`;

const Tab = styled.div<{ $isSelected: boolean }>`
    cursor: pointer;

    flex-shrink: 0;

    background-color: ${({ $isSelected }) => ($isSelected ? '#1f1f1f' : '#181818')};

    display: flex;
    align-items: center;
    padding: 5px 10px;

    border: 0.1px solid #43423d;
    border-bottom: ${({ $isSelected }) => ($isSelected ? '0' : '0.1px solid #43423d')};
    border-top: 0.1px solid ${({ $isSelected }) => ($isSelected ? '#14a9ff' : ' #43423d')};

    color: #cccccc;

    gap: 5px;

    &:hover {
        & img {
            display: block;
        }
    }
`;

const Filler = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    border-bottom: 0.1px solid #43423d;
`;

const TabIcon = styled.img`
    width: 20px;
    height: 20px;
`;

const CloseIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    padding: 2px;

    border-radius: 5px;

    &:hover {
        background-color: #ffffff20;
    }
`;
const CloseIcon = styled.img`
    width: 14px;
    height: 14px;

    display: none;
`;

const TabTitle = styled.div`
    font-family: 'RobotoRegular';
    font-size: 12px;
`;
