import styled from 'styled-components';
import { SideBar } from './SideBar';
import { TopBar } from './TopBar';
import { useState } from 'react';
import { Extension, FileType, readMeFile } from './directories';
import { TabBar } from './TabBar';
import { CodeContent } from './CodeContent';
import { ImageContent } from './ImageContent';

export const Dev = () => {
    const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0);
    const [openedFiles, setOpenedFiles] = useState<FileType[]>([readMeFile]);

    function addOpenedFile(file: FileType) {
        const fileIndex = openedFiles.findIndex(({ title }) => title === file.title);

        if (fileIndex === -1) {
            setOpenedFiles([...openedFiles, file]);
            setSelectedFileIndex(openedFiles.length);
        } else {
            setSelectedFileIndex(fileIndex);
        }
    }

    function closeOpenedFile(fileIndex: number) {
        if (selectedFileIndex >= openedFiles.length - 1) {
            setSelectedFileIndex(openedFiles.length - 2);
        }
        setOpenedFiles(openedFiles.filter((_, index) => index !== fileIndex));
    }

    function updateSelectedFileContent(content: string) {
        const newOpenedFiles = [...openedFiles];
        newOpenedFiles[selectedFileIndex].content = content;
        setOpenedFiles(newOpenedFiles);
    }

    const openedFile = openedFiles[selectedFileIndex];

    return (
        <OuterContainer>
            <TopBar addOpenedFile={addOpenedFile} />
            <Container>
                <SideBar addOpenedFile={addOpenedFile} />
                <ContentContainer>
                    <TabBar
                        closeOpenedFile={closeOpenedFile}
                        selectedFileIndex={selectedFileIndex}
                        setSelectedFileIndex={setSelectedFileIndex}
                        openedFiles={openedFiles}
                    />
                    <FileContainer>
                        {openedFile ? (
                            openedFile.extension === Extension.PNG ? (
                                <ImageContent selectedFileIndex={selectedFileIndex} openedFiles={openedFiles} />
                            ) : (
                                <CodeContent
                                    selectedFileIndex={selectedFileIndex}
                                    openedFiles={openedFiles}
                                    updateSelectedFileContent={updateSelectedFileContent}
                                />
                            )
                        ) : null}
                    </FileContainer>
                </ContentContainer>
            </Container>
        </OuterContainer>
    );
};

const OuterContainer = styled.div`
    background-color: #1f1f1f;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: hidden;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
`;

const FileContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    overflow: auto;
    flex-grow: 1;
`;
