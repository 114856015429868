import { useFrame, useThree } from '@react-three/fiber';
import { Float, Image, RoundedBox, Text } from '@react-three/drei';
import { Group, MathUtils, MeshMatcapMaterial, TextureLoader } from 'three';
import { useRef, useState } from 'react';
import { BluetoothWave } from './BluetoothWave';
import { whiteBasicMaterial } from '../../basicComponents/threeConstant';

const textureLoader = new TextureLoader();
export const matCap = textureLoader.load('/matcap/matcap1.png');
const material = new MeshMatcapMaterial({ matcap: matCap });

export function Bluetooth() {
    const { width, height } = useThree((state) => state.viewport);

    const [bluetoothWaves, setBluetoothWaves] = useState<{ timestamp: number; isRight: boolean }[]>([]);
    const [rotation, setRotation] = useState<number>(Math.PI);

    const controllerWidth = width * 0.15;
    const controllerHeight = controllerWidth * 0.4;
    const controllerDepth = controllerWidth * 0.2;

    const groupRef = useRef<Group>(null);

    useFrame((_, delta) => {
        if (!groupRef.current) {
            return;
        }

        groupRef.current.rotation.y = MathUtils.damp(groupRef.current.rotation.y, rotation, 4, delta);
    });

    return (
        <group dispose={null} rotation-x={0.2} position-x={width * 0.3} position-y={-height * 0.65} position-z={-1}>
            <Float
                speed={3} // Animation speed, defaults to 1
                rotationIntensity={0.2} // XYZ rotation intensity, defaults to 1
                floatIntensity={0.5} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                floatingRange={[-0.1, 0.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
            >
                <RoundedBox
                    args={[controllerWidth, controllerHeight, controllerDepth]} // Width, height, depth. Default is [1, 1, 1]
                    radius={0.1} // Radius of the rounded corners. Default is 0.05
                    smoothness={10} // The number of curve segments. Default is 4
                    bevelSegments={10} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
                    creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
                    material={material}
                ></RoundedBox>
                <mesh position-y={controllerHeight / 2} material={material}>
                    <cylinderGeometry args={[controllerWidth * 0.015, controllerWidth * 0.03, controllerHeight]} />
                </mesh>
                <mesh position-y={controllerHeight} material={material}>
                    <sphereGeometry args={[controllerWidth * 0.03, 8, 8]} />
                </mesh>

                <group>
                    <Text
                        position={[0, -0.8, 0]}
                        fontSize={0.2}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="center"
                        lineHeight={1}
                        material={whiteBasicMaterial}
                    >
                        Click me !
                    </Text>
                </group>

                <group position-z={controllerDepth / 2 + 0.01}>
                    <Text
                        anchorX="center"
                        anchorY="middle"
                        fontSize={controllerWidth * 0.25}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="center"
                        lineHeight={1}
                        maxWidth={controllerWidth * 0.8}
                        position-x={controllerWidth * 0.25}
                        onClick={() =>
                            setBluetoothWaves((prev) => [...prev, { timestamp: new Date().getTime(), isRight: true }])
                        }
                        onPointerEnter={() => {
                            document.body.style.cursor = 'pointer';
                        }}
                        onPointerLeave={() => {
                            document.body.style.cursor = 'default';
                        }}
                        material={whiteBasicMaterial}
                    >
                        ➡️
                    </Text>
                    <Text
                        anchorX="center"
                        anchorY="middle"
                        fontSize={controllerWidth * 0.25}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="center"
                        lineHeight={1}
                        maxWidth={controllerWidth * 0.8}
                        position-x={-controllerWidth * 0.25}
                        onClick={() =>
                            setBluetoothWaves((prev) => [...prev, { timestamp: new Date().getTime(), isRight: false }])
                        }
                        onPointerEnter={() => {
                            document.body.style.cursor = 'pointer';
                        }}
                        onPointerLeave={() => {
                            document.body.style.cursor = 'default';
                        }}
                        material={whiteBasicMaterial}
                    >
                        ⬅️
                    </Text>
                </group>
                <group ref={groupRef} position-y={controllerHeight / 2 + controllerHeight + 2 * controllerHeight}>
                    <mesh material={material}>
                        <boxGeometry args={[controllerWidth / 2, controllerWidth / 2, controllerWidth / 2]} />
                    </mesh>
                    <Image
                        url={'/images/badges/bluetooth.png'}
                        transparent
                        scale={[controllerWidth / 4, controllerWidth / 4]}
                        toneMapped={false}
                        position-z={-controllerWidth / 4 - 0.01}
                        rotation-y={Math.PI}
                    />
                    <Text
                        anchorX="center"
                        anchorY="middle"
                        fontSize={controllerWidth * 0.08}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="center"
                        lineHeight={1}
                        maxWidth={controllerWidth * 0.8}
                        position-z={controllerWidth / 4 + 0.01}
                        material={whiteBasicMaterial}
                    >
                        Connected
                    </Text>
                    <Text
                        anchorX="center"
                        anchorY="middle"
                        fontSize={controllerWidth * 0.12}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="center"
                        lineHeight={1}
                        maxWidth={controllerWidth * 0.8}
                        rotation-y={Math.PI / 2}
                        position-x={controllerWidth / 4 + 0.01}
                        material={whiteBasicMaterial}
                    >
                        ⬅️
                    </Text>
                    <Text
                        anchorX="center"
                        anchorY="middle"
                        fontSize={controllerWidth * 0.12}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="center"
                        lineHeight={1}
                        maxWidth={controllerWidth * 0.8}
                        rotation-y={-Math.PI / 2}
                        position-x={-controllerWidth / 4 - 0.01}
                        material={whiteBasicMaterial}
                    >
                        ➡️
                    </Text>
                </group>
                {bluetoothWaves.map(({ timestamp, isRight }) => {
                    return (
                        <BluetoothWave
                            key={timestamp}
                            timestamp={timestamp}
                            delay={2}
                            distance={controllerHeight * 2}
                            startPosition={controllerHeight}
                            width={controllerWidth * 0.03}
                            onDelete={() => {
                                setBluetoothWaves((prev) => prev.filter((value) => value.timestamp !== timestamp));
                                setRotation((prev) => prev + (isRight ? -0.1 : 0.1));
                            }}
                        />
                    );
                })}
            </Float>
        </group>
    );
}
