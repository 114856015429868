import styled from 'styled-components';

export const Classic = () => {
    return <Container></Container>;
};

const Container = styled.div`
    background-color: #1f1f1f;
    display: flex;
    flex-direction: column;
`;
