import { enigmoCode } from './fake/Enigmo';
import { oneflashCode } from './fake/OneFlash';
import { secretCode } from './fake/Secret';
import { totemCode } from './fake/Totem';

export enum Extension {
    Typescript = 'Typescript',
    JSON = 'JSON',
    CSS = 'CSS',
    PNG = 'PNG',
    React = 'React',
    Markdown = 'Markdown',
}

export function getSvgForExtension(extension: Extension) {
    switch (extension) {
        case Extension.Typescript:
            return '/svgs/typescript.svg';
        case Extension.JSON:
            return '/svgs/json.svg';
        case Extension.CSS:
            return '/svgs/css.svg';
        case Extension.PNG:
            return '/svgs/photo.svg';
        case Extension.React:
            return '/svgs/react.svg';
        case Extension.Markdown:
            return '/svgs/info.svg';
    }
}

export type FileType = { title: string; extension: Extension; content: string };
export type DirectoryType = { title: string; isOpen: boolean; directories: (DirectoryType | FileType)[] };

export const readMeFile = {
    title: 'README.md',
    extension: Extension.Markdown,
    content: `# Portfolio

## Tutorial

Welcome to our portfolio developper version. You will find all the information in the files on the left. Click on a file name to open it in the corresponding editor.

You can write in the files. As long as the files are open, anything you write will remain. To reset a file, close it or reload the page.

Happy coding!

## Team

Maxence Gilliot
Victor Gobel

## Easter eggs

The easter eggs are in the Space version!
`,
};

export const rootDirectory: DirectoryType = {
    title: 'portfolio',
    isOpen: true,
    directories: [
        {
            title: 'src',
            isOpen: true,
            directories: [
                {
                    title: 'projects',
                    isOpen: true,
                    directories: [
                        {
                            title: 'oneflash',
                            isOpen: true,
                            directories: [
                                {
                                    title: 'images',
                                    isOpen: false,
                                    directories: [
                                        {
                                            title: 'oneflash-1.png',
                                            extension: Extension.PNG,
                                            content: '/images/oneflash/1.png',
                                        },
                                        {
                                            title: 'oneflash-2.png',
                                            extension: Extension.PNG,
                                            content: '/images/oneflash/2.png',
                                        },
                                        {
                                            title: 'oneflash-3.png',
                                            extension: Extension.PNG,
                                            content: '/images/oneflash/3.png',
                                        },
                                    ],
                                },
                                {
                                    title: 'OneFlash.tsx',
                                    extension: Extension.React,
                                    content: oneflashCode,
                                },
                            ],
                        },
                        {
                            title: 'secret',
                            isOpen: true,
                            directories: [
                                {
                                    title: 'images',
                                    isOpen: false,
                                    directories: [
                                        {
                                            title: 'secret-1.png',
                                            extension: Extension.PNG,
                                            content: '/images/naviwatt/1.png',
                                        },
                                        {
                                            title: 'secret-2.png',
                                            extension: Extension.PNG,
                                            content: '/images/naviwatt/2.png',
                                        },
                                        {
                                            title: 'secret-3.png',
                                            extension: Extension.PNG,
                                            content: '/images/naviwatt/3.png',
                                        },
                                    ],
                                },
                                {
                                    title: 'Secret.tsx',
                                    extension: Extension.React,
                                    content: secretCode,
                                },
                            ],
                        },
                        {
                            title: 'enigmo',
                            isOpen: true,
                            directories: [
                                {
                                    title: 'images',
                                    isOpen: false,
                                    directories: [
                                        {
                                            title: 'enigmo-1.png',
                                            extension: Extension.PNG,
                                            content: '/images/enigmo/1.jpeg',
                                        },
                                        {
                                            title: 'enigmo-2.png',
                                            extension: Extension.PNG,
                                            content: '/images/enigmo/2.jpeg',
                                        },
                                        {
                                            title: 'enigmo-3.png',
                                            extension: Extension.PNG,
                                            content: '/images/enigmo/3.jpeg',
                                        },
                                        {
                                            title: 'enigmo-4.png',
                                            extension: Extension.PNG,
                                            content: '/images/enigmo/4.jpeg',
                                        },
                                        {
                                            title: 'enigmo-5.png',
                                            extension: Extension.PNG,
                                            content: '/images/enigmo/5.jpeg',
                                        },
                                        {
                                            title: 'enigmo-6.png',
                                            extension: Extension.PNG,
                                            content: '/images/enigmo/6.jpeg',
                                        },
                                    ],
                                },
                                {
                                    title: 'Enigmo.tsx',
                                    extension: Extension.React,
                                    content: enigmoCode,
                                },
                            ],
                        },
                        {
                            title: 'totem',
                            isOpen: true,
                            directories: [
                                {
                                    title: 'images',
                                    isOpen: false,
                                    directories: [
                                        {
                                            title: 'totem-1.png',
                                            extension: Extension.PNG,
                                            content: '/images/totem/1.jpeg',
                                        },
                                        {
                                            title: 'totem-2.png',
                                            extension: Extension.PNG,
                                            content: '/images/totem/2.jpeg',
                                        },
                                        {
                                            title: 'totem-3.png',
                                            extension: Extension.PNG,
                                            content: '/images/totem/3.jpeg',
                                        },
                                        {
                                            title: 'totem-4.png',
                                            extension: Extension.PNG,
                                            content: '/images/totem/4.jpeg',
                                        },
                                        {
                                            title: 'totem-5.png',
                                            extension: Extension.PNG,
                                            content: '/images/totem/5.jpeg',
                                        },
                                        {
                                            title: 'totem-6.png',
                                            extension: Extension.PNG,
                                            content: '/images/totem/6.jpeg',
                                        },
                                    ],
                                },
                                {
                                    title: 'Totem.tsx',
                                    extension: Extension.React,
                                    content: totemCode,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'contact.css',
            extension: Extension.CSS,
            content: `html,
body {
    margin: 0;
    height: 100%;
    overflow: hidden;
}

.contact {
    content: url('admin@enigmo-games.com');
}
`,
        },
        readMeFile,
        {
            title: 'stack.ts',
            extension: Extension.Typescript,
            content: `export enum MainStack {
    ApolloGraphQL = 'ApolloGraphQL',
    ExpoReactNative = 'ExpoReactNative',
    Kubernetes = 'Kubernetes',
    MongoDB = 'MongoDB',
    NodeJS = 'NodeJS',
    React = 'React',
    Typescript = 'Typescript',
}

export enum SecondaryStack {
    BluetoothLowEnergy = 'BluetoothLowEnergy',
    GoogleCloudPlatform = 'GoogleCloudPlatform',
    Intercom = 'Intercom',
    Internationalization = 'Internationalization',
    MQTT = 'MQTT',
    ReactNativeSkia = 'ReactNativeSkia',
    SQL = 'SQL',
    Stripe = 'Stripe',
    StyledComponents = 'StyledComponents',
    ThreeJS = 'ThreeJS',
}

export enum DevName {
    VictorGobel = 'VictorGobel',
    MaxenceGilliot = 'MaxenceGilliot',
}

export type EnigmoDev = {
    isAgile: true;
    isGoodProductEngineer: true;
    name: DevName;
    stacks: (MainStack | SecondaryStack)[];
    workStartYear: 2018;
};`,
        },
        {
            title: 'values.json',
            extension: Extension.JSON,
            content: `{
    "values": [
        {
            "title": "Pragmatism",
            "description": "We develop efficiently without over engineering. We aim to create complex system without unecessary complex code.",
            "object3D": "TargetStand",
        },
        {
            "title": "Swiftness",
            "description": "With our years of experience and our team synergy, we can deliver your project in a few weeks only.",
            "object3D": "SpaceShip",
        },
        {
            "title": "Communication",
            "description": "Communication is key for us, we keep you updated with regular update on the developed features or the issues encountered.",
            "object3D": "Book",
        },
    ],
}`,
        },
    ],
};
