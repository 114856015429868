import { Float, RoundedBox, Text } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useRef, useState } from 'react';
import { Group, MathUtils } from 'three';

const questions = [
    {
        title: 'What is the name of our freelance company ?',
        answers: ['Freenigm', 'Enigmo', 'Dev Mystery', 'Qwest'],
        correctAnswerIndex: 1,
    },
    {
        title: 'What colour are the lights when you return all the power banks?',
        answers: ['Yellow', 'Blue', 'Violet', 'Green'],
        correctAnswerIndex: 2,
    },
    {
        title: 'What secret message is hidden in the Bluetooth device?',
        answers: ['Connected', 'Secret', 'Bluetooth', 'Skia'],
        correctAnswerIndex: 0,
    },
    {
        title: 'How many clouds are in the Enigmo Game City ?',
        answers: ['1', '2', '3', '4'],
        correctAnswerIndex: 1,
    },
    {
        title: 'In the completed fridge, which soda can color is the most represented ?',
        answers: ['blue', 'green', 'red', 'yellow'],
        correctAnswerIndex: 2,
    },
];

export const Quiz = () => {
    const { width, height } = useThree((state) => state.viewport);

    const questionRef = useRef<Group>(null);

    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const [hasAnswered, setHasAnswered] = useState<boolean>(false);
    const [hasWronglyAnswered, setHasWronglyAnswered] = useState<boolean>(false);

    function answerQuestion({ answer }: { answer: number }) {
        const question = questions[questionIndex];

        if (answer === question.correctAnswerIndex) {
            setHasAnswered(true);
            setTimeout(() => {
                setHasAnswered(false);
                setQuestionIndex((questionIndex + 1) % questions.length);
            }, 800);
        } else {
            setHasWronglyAnswered(true);
            setTimeout(() => {
                setHasWronglyAnswered(false);
            }, 500);
        }
    }

    useFrame(({ clock }, delta) => {
        if (!questionRef.current) {
            return;
        }

        questionRef.current.rotation.y = MathUtils.damp(
            questionRef.current.rotation.y,
            hasAnswered ? (questionIndex % 2 ? Math.PI : -Math.PI) : 0,
            4,
            delta
        );

        questionRef.current.rotation.z = MathUtils.damp(
            questionRef.current.rotation.z,
            hasWronglyAnswered ? (-Math.PI / 6) * Math.sin(clock.getElapsedTime() * 50) : 0,
            4,
            delta
        );
    });

    const basicWidth = 0.4 * width;
    const basicHeight = 0.14 * height;

    return (
        <group>
            <Float
                speed={3} // Animation speed, defaults to 1
                rotationIntensity={0.3} // XYZ rotation intensity, defaults to 1
                floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                floatingRange={[-0.1, 0.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
            >
                <group position={[0, -height * 0.4, 0]}>
                    <group ref={questionRef}>
                        <group position={[0, 0, 0]}>
                            <mesh position={[0, 0, -0.3]}>
                                <RoundedBox args={[basicWidth + 0.1, basicHeight, 0.1]}>
                                    <meshBasicMaterial color={'#fff0de'} toneMapped={false} />
                                </RoundedBox>
                            </mesh>
                            <Text
                                fontSize={0.2}
                                fontWeight={700}
                                letterSpacing={-0.02}
                                textAlign="center"
                                lineHeight={1}
                                clipRect={[
                                    -basicWidth / 2 + 0.1,
                                    basicHeight / 2 - 0.1,
                                    basicWidth / 2 - 0.1,
                                    -basicHeight / 2 + 0.1,
                                ]}
                                whiteSpace="overflowWrap"
                                maxWidth={basicWidth - 0.2}
                            >
                                {questions[questionIndex].title}
                                <meshBasicMaterial color={'#000000'} toneMapped={false} />
                            </Text>
                        </group>
                        <group position={[0, -2.5 * basicHeight, 0]}>
                            {questions[questionIndex].answers.map((answer, index) => {
                                return (
                                    <group
                                        key={index}
                                        position={[
                                            (basicWidth / 4 + 0.05) * ((index % 2) * 2 - 1),
                                            (-basicHeight - 0.1) * (Math.floor(index / 2) - 1),
                                            0,
                                        ]}
                                        onClick={() => answerQuestion({ answer: index })}
                                        onPointerEnter={() => {
                                            document.body.style.cursor = 'pointer';
                                        }}
                                        onPointerLeave={() => {
                                            document.body.style.cursor = 'default';
                                        }}
                                    >
                                        <mesh position={[0, 0, -0.3]}>
                                            <RoundedBox args={[basicWidth / 2, basicHeight, 0.1]}>
                                                <meshBasicMaterial color={'#fff0de'} toneMapped={false} />
                                            </RoundedBox>
                                        </mesh>
                                        <Text
                                            fontSize={0.15}
                                            fontWeight={700}
                                            letterSpacing={-0.02}
                                            textAlign="center"
                                            lineHeight={1}
                                            clipRect={[
                                                -basicWidth / 4 + 0.1,
                                                basicHeight / 2 - 0.1,
                                                basicWidth / 4 - 0.1,
                                                -basicHeight / 2 + 0.1,
                                            ]}
                                            whiteSpace="overflowWrap"
                                            maxWidth={basicWidth / 2 - 0.2}
                                        >
                                            {answer}
                                            <meshBasicMaterial color={'#000000'} toneMapped={false} />
                                        </Text>
                                    </group>
                                );
                            })}
                        </group>
                    </group>
                </group>
            </Float>
        </group>
    );
};
