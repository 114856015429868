import Editor from 'react-simple-code-editor';

import { Grammar, highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-tsx';

import 'prismjs/themes/prism-tomorrow.css'; //Example style, you can use another
import { Extension, FileType } from './directories';

const hightlightWithLineNumbers = (text: string, grammar: Grammar, language: string) =>
    highlight(text, grammar, language)
        .split('\n')
        .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
        .join('\n');

function getGrammarFromExtension(extension: Extension) {
    switch (extension) {
        case Extension.React:
            return languages.tsx;
        case Extension.Typescript:
            return languages.tsx;
        case Extension.JSON:
            return languages.json;
        case Extension.CSS:
            return languages.css;
        default:
            return languages.markdown;
    }
}

function getLanguageFromExtension(extension: Extension) {
    switch (extension) {
        case Extension.React:
            return 'tsx';
        case Extension.Typescript:
            return 'tsx';
        case Extension.JSON:
            return 'json';
        case Extension.CSS:
            return 'css';
        default:
            return 'markdown';
    }
}

export const CodeContent = ({
    selectedFileIndex,
    updateSelectedFileContent,
    openedFiles,
}: {
    selectedFileIndex: number;
    updateSelectedFileContent: (content: string) => void;
    openedFiles: FileType[];
}) => {
    const selectedFile = openedFiles[selectedFileIndex];

    return (
        <div>
            <Editor
                value={selectedFile.content}
                onValueChange={(code) => updateSelectedFileContent(code)}
                highlight={(code) =>
                    hightlightWithLineNumbers(
                        code,
                        getGrammarFromExtension(selectedFile.extension),
                        getLanguageFromExtension(selectedFile.extension)
                    )
                }
                padding={10}
                textareaId="codeArea"
                className="editor"
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 14,
                    lineHeight: 1.7,
                    outline: 0,
                }}
            />
        </div>
    );
};
