import { useThree } from '@react-three/fiber';
import { FloatingObjects } from './3D/FloatingObjects';
import { OurValues } from './3D/OurValues';
import { ProjectEnigmo } from './3D/projects/ProjectEnigmo';
import { ProjectNaviwatt } from './3D/projects/ProjectNaviwatt';
import { ProjectOneflash } from './3D/projects/ProjectOneflash';
import { ProjectTotem } from './3D/projects/ProjectTotem';
import { Quiz } from './3D/projects/Quiz';
import { OurStack } from './3D/OurStack';

export const Space3DScroll = () => {
    const { height } = useThree((state) => state.viewport);

    return (
        <group>
            <FloatingObjects />
            <group position-y={-4.7 * height}>
                <OurStack />
            </group>
            <group position-y={-5.6 * height}>
                <OurValues />
            </group>
            <group position-y={-6.7 * height}>
                <group position-y={0 * height}>
                    <ProjectOneflash />
                </group>
                <group position-y={-1.1 * 1 * height}>
                    <ProjectNaviwatt />
                </group>
                <group position-y={-1.1 * 2 * height}>
                    <ProjectEnigmo />
                </group>
                <group position-y={-1.1 * 3 * height}>
                    <ProjectTotem />
                </group>
                <group position-y={-1.1 * 4 * height}>
                    <Quiz />
                </group>
            </group>
        </group>
    );
};
