import styled from 'styled-components';
import { useScroll } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { EnigmoTitle } from './HTML/EnigmoTitle';
import { OurValues } from './HTML/OurValues';
import { OurProjects } from './HTML/OurProjects';
import { Credits } from './HTML/Credits';
import { Contact } from './HTML/Contact';
import { EasterEggs } from './HTML/EasterEggs';
import { OurStack } from './HTML/OurStack';

export const SpaceHTMLScroll = () => {
    const scrollText = useRef<HTMLDivElement>(null);
    const scroll = useScroll();

    useFrame(() => {
        if (!scrollText.current) {
            return;
        }
        const range1 = scroll.range(0, 0.5 / scroll.pages);
        scrollText.current.style.opacity = (1 - range1).toString();
    });

    return (
        <div style={{ position: 'relative' }}>
            <Section>
                <EnigmoTitle />
            </Section>
            <div style={{ height: '360vh' }} />
            <Section>
                <OurStack />
            </Section>
            <Section>
                <OurValues />
            </Section>
            <div style={{ height: '40vh' }} />
            <OurProjects />
            {/* Oneflash project section*/}
            <Section></Section>
            {/* Naviwatt project section*/}
            <Section></Section>
            {/* Enigmo project section*/}
            <Section></Section>
            {/* Totem project section*/}
            <Section></Section>
            <div style={{ height: `${10 * 4}vh` }} />
            {/* Quiz section*/}
            <Section>
                <EasterEggs />
            </Section>
            <div style={{ height: `${10 * 5}vh` }} />
            <Section>
                <Contact />
            </Section>
            {/* <Section>
                <OurTeam />
            </Section> */}
            <Section>
                <Credits />
            </Section>
        </div>
    );
};

const Section = styled.div`
    width: 100vw;
    height: 100vh;
`;
