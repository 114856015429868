import { useFrame } from '@react-three/fiber';
import { Float, Text, useScroll } from '@react-three/drei';
import { useRef } from 'react';

export const AppearingSlogan = () => {
    const scroll = useScroll();
    const textRef = useRef<typeof Text>(null);

    useFrame(() => {
        if (!textRef.current) {
            return;
        }

        const r1 = scroll.range(1.8 / scroll.pages, 1 / scroll.pages);

        //@ts-ignore
        textRef.current.fillOpacity = r1;
        //@ts-ignore
        textRef.current.outlineOpacity = r1;
    });

    return (
        <Float
            speed={0} // Animation speed, defaults to 1
            rotationIntensity={0.2} // XYZ rotation intensity, defaults to 1
            floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            floatingRange={[-0.1, 0.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
        >
            <group>
                <group rotation={[0.2, 0, 0]}>
                    <Text
                        ref={textRef}
                        fontSize={0.6}
                        fontWeight={700}
                        letterSpacing={-0.02}
                        textAlign="center"
                        lineHeight={1}
                        outlineColor={'#5a88a7'}
                        outlineOffsetX={'5%'}
                        outlineOffsetY={'5%'}
                    >
                        {'Full Stack Developers'}
                        {'\n'}
                        {'Web - Mobile - Back - DevOps'}
                        <meshBasicMaterial color={'#ffffff'} toneMapped={false} depthTest={false} />
                    </Text>
                </group>
            </group>
        </Float>
    );
};
