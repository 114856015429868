import styled from 'styled-components';
import { DirectoryType, FileType, getSvgForExtension, rootDirectory } from './directories';

const File = ({
    file,
    depth,
    addOpenedFile,
}: {
    file: FileType;
    depth: number;
    addOpenedFile: (file: FileType) => void;
}) => {
    const svgForExtension = getSvgForExtension(file.extension);

    return (
        <DirectoryDetails
            open
            $depth={depth}
            $closedSvg={svgForExtension}
            $openSvg={svgForExtension}
            onClick={() => addOpenedFile(file)}
        >
            <DirectorySummary>{file.title}</DirectorySummary>
        </DirectoryDetails>
    );
};
const Directory = ({
    directory,
    depth,
    addOpenedFile,
}: {
    directory: DirectoryType;
    depth: number;
    addOpenedFile: (file: FileType) => void;
}) => {
    return (
        <DirectoryDetails
            open={directory.isOpen}
            $depth={depth}
            $closedSvg={'/svgs/chevron-right.svg'}
            $openSvg={'/svgs/chevron-down.svg'}
        >
            <DirectorySummary>{directory.title}</DirectorySummary>
            <SubDirectoryContainer>
                {directory.directories?.map((subDirectory, index) =>
                    'directories' in subDirectory ? (
                        <Directory
                            key={index}
                            directory={subDirectory}
                            depth={depth + 1}
                            addOpenedFile={addOpenedFile}
                        />
                    ) : 'extension' in subDirectory ? (
                        <File key={index} file={subDirectory} depth={depth + 1} addOpenedFile={addOpenedFile} />
                    ) : null
                )}
            </SubDirectoryContainer>
        </DirectoryDetails>
    );
};

export const SideBar = ({ addOpenedFile }: { addOpenedFile: (file: FileType) => void }) => {
    return (
        <Container>
            <Directory directory={rootDirectory} depth={0} addOpenedFile={addOpenedFile} />
        </Container>
    );
};

const Container = styled.div`
    background-color: #181818;
    font-family: 'RobotoRegular';
    font-size: 12px;
    width: 200px;
    padding-top: 10px;
    padding-bottom: 10px;

    flex-shrink: 0;

    border-right: 0.1px solid #43423d;
    overflow: auto;

    scrollbar-color: #747474 transparent;
    scrollbar-width: thin;
`;

const DirectoryDetails = styled.details<{ $depth: number; $closedSvg: string; $openSvg: string }>`
    color: white;
    cursor: pointer;

    & > summary {
        color: #cccccc;
        list-style: none;

        padding: 3px 3px 3px ${({ $depth }) => $depth * 13 + 23}px;

        background: url(${({ $closedSvg }) => $closedSvg}) no-repeat ${({ $depth }) => $depth * 13 + 3}px 50%;
        background-size: 20px 20px;

        &:hover {
            background-color: #5c5c5c;
        }
    }

    &[open] > summary {
        background: url(${({ $openSvg }) => $openSvg}) no-repeat ${({ $depth }) => $depth * 13 + 3}px 50%;
        background-size: 20px 20px;

        &:hover {
            background-color: #5c5c5c;
        }
    }
`;

const DirectorySummary = styled.summary``;

const SubDirectoryContainer = styled.div`
    /* padding-left: 13px; */
`;
