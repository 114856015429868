import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InkCursor } from './InkCursor';

export const Home = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <BackgroundTitle>
                <Letter>E</Letter>
                <Letter>N</Letter>
                <Letter>I</Letter>
                <Letter>G</Letter>
                <Letter>M</Letter>
                <Letter>O</Letter>
            </BackgroundTitle>
            <TitleContainer>
                <Title $animationDelay={300}>Hello there!</Title>
                <Title $animationDelay={450}>
                    Are <ColorSpan $color={'#f7ffd7'}>you</ColorSpan> looking for
                </Title>
                <Title $animationDelay={600}>
                    fullstack <ColorSpan $color={'#ffffff'}>developpers</ColorSpan>?
                </Title>
            </TitleContainer>
            <TitleContainer style={{ textAlign: 'right' }}>
                <Title $animationDelay={1300}>
                    <ColorSpan $color={'#ffffff'}>Luckily</ColorSpan> for <ColorSpan $color={'#f7ffd7'}>you</ColorSpan>,
                    we are!
                </Title>
                <Title $animationDelay={1450}>
                    But who are <ColorSpan $color={'#f7ffd7'}>you</ColorSpan>?
                </Title>
            </TitleContainer>
            <ButtonsContainer $animationDelay={2300}>
                <Button onClick={() => navigate('product')}>Product Owner</Button>
                <Button onClick={() => navigate('dev')}>Developper</Button>
                <Button onClick={() => navigate('space')}>Space Cowboy</Button>
            </ButtonsContainer>
            <InkCursor />
        </Container>
    );
};

const Container = styled.div`
    cursor: none;
    /* background-color: #d6d1ff; */
    position: relative;
    /* width: 100%; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    padding: 0 8vh;
`;

const ButtonsContainer = styled.div<{ $animationDelay: number }>`
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3vw;
    margin-top: 8vh;

    animation: slideIn 500ms ease-in-out;
    animation-fill-mode: both;
    animation-delay: ${({ $animationDelay }) => $animationDelay}ms;
`;

const Button = styled.button`
    font-size: 3vh;

    padding: 10px 20px;
    background-color: #090611;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-family: 'RobotoBold';
    border-radius: 10px;
    cursor: none;
    transition: all 0.5s;

    &:hover {
        background-color: #f7ffd7;
        border: 2px solid #f7ffd7;
        color: #090611;
        transform: translateY(-5px);
    }
`;

const TitleContainer = styled.div`
    z-index: 1;
    margin-top: 8vh;
`;

const Title = styled.div<{ $animationDelay: number }>`
    color: #ffffff;
    font-family: 'RobotoBold';
    letter-spacing: -0.3vh;
    font-size: 10vh;

    text-shadow: #000000 2px 2px 0px;

    animation: slideIn 500ms ease-in-out;
    animation-fill-mode: both;
    animation-delay: ${({ $animationDelay }) => $animationDelay}ms;

    opacity: 0;

    @keyframes slideIn {
        0% {
            transform: translateY(10px);
            opacity: 0;
        }
        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }
`;

const ColorSpan = styled.span<{ $color: string }>`
    color: ${({ $color }) => $color};
`;

const BackgroundTitle = styled.div`
    position: absolute;
    top: 8vh;
    right: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    z-index: 0;
`;

const Letter = styled.div`
    font-family: 'Plumpfull';
    font-size: 1vw;
    color: #f7ffd7;
    transition: all 0.5s;

    /* text-shadow: #f5fcd6 -0px 0px 3px; */
`;
