import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { animated, SpringValue, useSprings } from '@react-spring/web';

const amount = 10;
const sineDots = Math.floor(amount * 1);
const width = 32;
const idleTimeout = 10000;

class Dot {
    index: number;
    anglespeed: number;
    x: number;
    y: number;
    scale: number;
    range: number;
    lockX: number;
    lockY: number;
    angleX: number;
    angleY: number;

    constructor(index = 0) {
        this.index = index;
        this.anglespeed = 0.05;
        this.x = window.innerWidth / 2;
        this.y = window.innerHeight / 2;
        this.scale = 1 - (1 / (amount * 2)) * index;
        this.range = width / 2 - (width / 2) * this.scale + 2;

        this.lockX = 0;
        this.lockY = 0;
        this.angleX = 0;
        this.angleY = 0;
    }

    lock() {
        this.lockX = this.x;
        this.lockY = this.y;
        this.angleX = Math.PI * 2 * Math.random();
        this.angleY = Math.PI * 2 * Math.random();
    }

    draw({
        isIdle,
        spring,
    }: {
        isIdle: boolean;
        spring: {
            left: SpringValue<number>;
            top: SpringValue<number>;
        };
    }) {
        if (!isIdle || this.index <= sineDots) {
            spring.left.set(this.x);
            spring.top.set(this.y);
        } else {
            this.angleX += this.anglespeed;
            this.angleY += this.anglespeed;
            this.y = this.lockY + Math.sin(this.angleY) * this.range;
            this.x = this.lockX + Math.sin(this.angleX) * this.range;
            spring.left.set(this.x);
            spring.top.set(this.y);
        }
    }
}

function buildDots() {
    const dots = [];
    for (let i = 0; i < amount; i++) {
        dots.push(new Dot(i));
    }

    return dots;
}

export const InkCursor = () => {
    const dots = useRef(buildDots());
    const requestFrame = useRef<number>();
    const timeoutId = useRef<NodeJS.Timeout>();
    const isIdle = useRef(false);

    const springs = useSprings(
        amount,
        dots.current.map((dot) => ({ left: dot.x, top: dot.y, scale: dot.scale }))
    );

    const mousePosition = useRef({ x: window.innerWidth / 2, y: window.innerHeight / 2 });

    useEffect(() => {
        function render() {
            positionCursor();
            requestFrame.current = requestAnimationFrame(render);
        }

        function positionCursor() {
            let x = mousePosition.current.x;
            let y = mousePosition.current.y;
            dots.current.forEach((dot, index, dots) => {
                const nextDot = dots[index + 1] || dots[0];
                dot.x = x;
                dot.y = y;
                dot.draw({ isIdle: isIdle.current, spring: springs[index] });
                if (!isIdle || index <= sineDots) {
                    const dx = (nextDot.x - dot.x) * 0.35;
                    const dy = (nextDot.y - dot.y) * 0.35;
                    x += dx;
                    y += dy;
                }
            });
        }

        function startIdleTimer() {
            timeoutId.current = setTimeout(goInactive, idleTimeout);
            isIdle.current = false;
        }

        function resetIdleTimer() {
            clearTimeout(timeoutId.current);
            startIdleTimer();
        }

        function goInactive() {
            isIdle.current = true;
            for (const dot of dots.current) {
                dot.lock();
            }
        }

        function onMouseMove(event: MouseEvent) {
            mousePosition.current.x = event.clientX - width / 2;
            mousePosition.current.y = event.clientY - width / 2;
            // resetIdleTimer();
        }

        function onTouchMove(event: TouchEvent) {
            mousePosition.current.x = event.touches[0].clientX - width / 2;
            mousePosition.current.y = event.touches[0].clientY - width / 2;
            // resetIdleTimer();
        }

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('touchmove', onTouchMove);

        render();

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('touchmove', onTouchMove);
            if (requestFrame.current) {
                cancelAnimationFrame(requestFrame.current);
            }
        };
    }, [mousePosition, springs, isIdle]);

    return (
        <Cursor>
            {springs.map((animatedStyles, index) => (
                <animated.span
                    key={index}
                    style={{
                        position: 'absolute',
                        display: 'block',
                        width: width,
                        height: width,
                        borderRadius: 20,
                        backgroundColor: '#f7ffd7',
                        // opacity: 1 - index / amount,
                        ...animatedStyles,
                    }}
                />
            ))}
            {new Array(30).fill(0).map((_, index) => (
                <DotElement
                    key={index}
                    style={{
                        left: `${Math.random() * 90 + 5}%`,
                        top: `${Math.random() * 90 + 5}%`,
                        scale: `${Math.random() / 2 + 0.5}`,
                    }}
                />
            ))}
        </Cursor>
    );
};

const Cursor = styled.div`
    pointer-events: none;
    position: fixed;
    display: block;
    border-radius: 0;
    transform-origin: center center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    mix-blend-mode: difference;
    /* filter: url('/svgs/ink-cursor.svg#goo'); */
`;

const DotElement = styled.div`
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    /* border: 8px solid #ffffff; */

    opacity: 0;

    --distanceX: ${() => (Math.random() > 0.5 ? 1 : -1) * Math.random() * 125 + 25}px;
    --distanceY: ${() => (Math.random() > 0.5 ? 1 : -1) * Math.random() * 125 + 25}px;
    animation: floatingBlob ${() => Math.random() * 10 + 10}s ease-in-out infinite;
    animation-delay: ${() => -Math.random() * 10}s;

    @keyframes floatingBlob {
        0% {
            transform: translateX(calc(-1 * var(--distanceX))) translateY(var(--distanceY));
            opacity: 0;
        }

        25% {
            opacity: 1;
        }

        50% {
            transform: translateX(0) translateY(calc(-1 * var(--distanceY)));
            opacity: 0;
        }

        75% {
            opacity: 1;
        }

        100% {
            transform: translateX(calc(-1 * var(--distanceX))) translateY(var(--distanceY));
            opacity: 0;
        }
    }
`;
