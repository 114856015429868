import { Route, Routes } from 'react-router-dom';
import { Space } from './components/space/Space';
import { Home } from 'components/home/Home';
import { Dev } from 'components/dev/Dev';
import { Classic } from 'components/classic/Classic';

function Routing() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/space" element={<Space />} />
            <Route path="/dev" element={<Dev />} />
            <Route path="/product" element={<Classic />} />
        </Routes>
    );
}

export default Routing;
