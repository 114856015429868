export const enigmoCode = `import styled from 'styled-components';

export const Enigmo = () => {
    return (
        <Container>
            <Title>Enigmo - Treasure hunts in your city</Title>
            <Section>
                <SectionTitle>Presentation</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        This is a mobile treasure hunt/guided tour application for France's major cities. Come and
                        discover the historical heritage of your city by solving puzzles.
                    </SectionParagraph>
                    <SectionParagraph>
                        To create the treasure hunts, we carried out historical research on the various sites in the
                        neighbourhoods. We then designed the appropriate puzzles.
                    </SectionParagraph>
                    <SectionParagraph>https://enigmo-games.com/</SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>Expo (React Native)</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        We developed the mobile application using Expo (React Native). This framework allows us to
                        develop iOS and Android versions in parallel.
                    </SectionParagraph>
                    <SectionParagraph>
                        The diversity of the puzzles led us to use phone-specific mechanics. For example, some puzzles
                        use the accelerometer, some use the user's position, some use the camera, and we even have an
                        augmented reality puzzle.
                    </SectionParagraph>
                    <SectionParagraph>
                        We've also set up an online chat to deal with urgent customer issues (so they don't get stuck in
                        the middle of a treasure hunt).
                    </SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>Translation</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        Tourists are one of our target audiences. So we needed to translate the application into a
                        variety of languages, including English.
                    </SectionParagraph>
                    <SectionParagraph>
                        We set up a translation system accessible from the mobile application, the shop site and the
                        admin site.
                    </SectionParagraph>
                    <SectionParagraph>
                        Translations are managed on the admin site: when creating a treasure hunt, each text field has
                        to be filled in in the different languages.
                    </SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>SEO</SectionTitle>
                <SectionContent>
                    <SectionParagraph>
                        To improve the SEO of our shop, we decided to use NextJS, a server-side rendering framework.
                    </SectionParagraph>
                    <SectionParagraph>
                        With the same goal in mind, we set up a sitemap generation system that takes into account
                        "canonical" or "alternate" urls (our urls are translated into the appropriate language for
                        better referencing).
                    </SectionParagraph>
                    <SectionParagraph>
                        We also followed best practices such as adding title tags, meta descriptions and robots.txt
                        files.
                    </SectionParagraph>
                    <SectionParagraph>
                        Finally, we added structured data (JSON-LD) to our product pages so that they appear in a
                        stylised way in searches.
                    </SectionParagraph>
                </SectionContent>
            </Section>
            <Section>
                <SectionTitle>Google Ads</SectionTitle>
                <SectionContent>
                    <SectionParagraph>To get the word out, we tried a number of marketing techniques.</SectionParagraph>
                    <SectionParagraph>
                        First, we partnered with Escape Days, an event that brings together many escape game brands.
                    </SectionParagraph>
                    <SectionParagraph>
                        Then we created an Instagram page where we posted mini-games once a week.
                    </SectionParagraph>
                    <SectionParagraph>
                        Finally, we used Google's AdSense to appear in sponsored results on Google searches.
                    </SectionParagraph>
                </SectionContent>
            </Section>
        </Container>
    );
};

const Container = styled.div\`
    background-color: #1f1f1f;
    color: #cccccc;
    padding: 10px;
    gap: 20px;
\`;

const Title = styled.h1\`
    font-size: 20px;
\`;

const Section = styled.div\`
    gap: 15px;
\`;
const SectionTitle = styled.h2\`
    font-size: 16px;
\`;
const SectionContent = styled.div\`
    gap: 10px;
\`;

const SectionParagraph = styled.span\`
    font-size: 14px;
\`;
`;
